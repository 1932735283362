import makePlan from "./../IntentionDetail/components/makePlan.vue";
import FillinfollowUp from './../IntentionDetail//components/FillinfollowUp'
import { getSList } from "@/views/userOperations/OpportunityList/api";
export default {
  components: {
    makePlan,
    FillinfollowUp,
  },
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),
      pageLoadFlag: false,
      isFixed: false,
      leftJl:'145',
      // 非配置筛选条件
      filterData: {
        orderBy: "plannedFollowUpTime  asc",// 排序选中的数据id
      },
      arg: {},
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/OpportunityList",
          name: "",
          title: "销售机会"
        }
      ],
      //列表数据
      listData: [],
      // 分页条件
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      cherZhname: "计划跟进时间",// 排序选中的名称
      isCherkZH: false,// 展示选中数据需要删除图标
      isShowMoreZH: false,// 综合更多选项展开收起
      isHaveVal: false,// 综合前边的删除图标是否展示
      filterLoadFlag: false,// 排序展示数据loading效果
      PxList: [
        // {
        //   id: "lastFollowUpTime  desc",
        //   name: "最后跟进时间"
        // },
        {
          id: "planBuyTime   asc",
          name: "(预计)成交时间"
        },
        {
          id: "budgetLower  desc",
          name: "(预计)成交金额"
        },
        {
          id: "plannedFollowUpTime  asc",
          name: "计划跟进时间"
        },
        {
          id: "modifiedDate  desc",
          name: "更新时间"
        }
      ],// 排序筛选列表

      tableData:[

      ],
      palnvisible:false,
      qugenjinvisible:false,
      userId:'',
      outId:'',
      efitInfo: {},
      status:undefined,
      pageElClickTime:'',

    };
  },
  mounted() {
    this.pageLoadFlag = true
    //页面元素点击时间 -》 更多筛选监听后关闭
    this.pageElClickTime = new Date().getTime();
    // this.getSaleList()

  },
  methods: {

    // 更多筛选点击
    formClick(isShow){
      if(isShow){
        this.isShowMoreYH = false;
        this.isShowMoreZH = false;
      }
    },
    // 新增销售机会
    addSale(){
      this.$router.push({
        path: "/IntendedUserEntry",
        query: {
          type:'saleList'
          // id:item.id,
          // userId:item.userId
        }
      });
    },
    // 获取列表
    getSaleList(){
      this.pageLoadFlag = true;
      let data = {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNum,
        ...this.arg,
        ...this.filterData
      };
      getSList(data).then(res=>{
        if(res.data.code == 0) {
          this.pageLoadFlag = false;
          this.tableData = res.data.list
          this.page.total = res.data.totalRows;
          if( this.tableData.length > 0){
            this.tableData.forEach(item=>{
              // 是否展示按钮
              item.showBtn = false
            })
          }
        } else{
          this.tableData=[]
          this.page.total=0
          this.pageLoadFlag = false;
          this.$message.warning(res.data.msg)
        }
      }).catch(error=>{
        this.pageLoadFlag = false;
      })

    },
    gotoDetail(item){
      this.$router.push({
        path: "/OpportunityDetail",
        query: {
          id:item.id,
          userId:item.userId
        }
      });
    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.showBtn = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.showBtn = false;
      this.$forceUpdate();
    },
    // 打开定计划弹窗
    openDJh(item){
      this.efitInfo = item;
      this.userId = String(item.userId)
      this.outId = String(item.id)
      this.palnvisible = true
    },
    // 制定计划
    cancelReasonHandOk(data){
      this.getSaleList()

    },
    openFillinfollowVisible(item){
      this.efitInfo = item;
      this.status = undefined
      this.status = item.status?Number(item.status):100
      this.userId = String(item.userId)
      this.outId = String(item.id)
      this.qugenjinvisible = true
    },
    // 填写跟进记录
    FillinfollowUpOk() {
      this.getSaleList()
    },

    //综合排序展开收起
    moreClick() {
      this.isShowMoreZH = !this.isShowMoreZH;
      if (this.isShowMoreZH) {
        this.isShowMoreYH = false;
      }
      //页面元素点击时间 -》 更多筛选监听后关闭
      this.pageElClickTime = new Date().getTime();
    },
    // 删除选中项目
    delectZH() {
      this.isShowMoreZH = false;
      this.isHaveVal = false;
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.filterData.orderBy = "plannedFollowUpTime  asc";
      this.cherZhname = '计划跟进时间'
      if( this.isHaveVal ) {
        this.leftJl = 176
      } else {
        this.leftJl = 145
      }
      this.$forceUpdate();
      this.getSaleList()
    },
    // 修改综合选项
    changeZH(item) {
      this.filterData.orderBy = item.id;
      this.cherZhname = item.name;
      if (this.filterData.orderBy) {
        this.isHaveVal = true;
      } else {
        this.isHaveVal = false;
      }
      this.isShowMoreZH = false;
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      if(this.isHaveVal ) {
        this.leftJl = 176
      } else {
        this.leftJl = 145
      }
      this.getSaleList();
    },
    searchFun(arg) {
      this.arg = arg[0];
      this.page.pageNum = 1;
      this.page.pageSize = 10;
      this.getSaleList();
    },
    // 分页
    handlePageChange(page, pageSize) {
      this.page.pageNum = page;
      this.page.pageSize = pageSize;
      this.getSaleList();
    },
  }
};
